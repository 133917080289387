<!--
- https://segmentfault.com/a/1190000040099324
-->
<template>
  <div class="login_container">
    <div class="login_box">
      <div class="avatar_box">
        <img src="../assets/logo.png" alt="" />
      </div>
      <el-form
        ref="loginFormRef"
        :model="loginForm"
        :rules="loginFormRules"
        label-width="0px"
        class="login_form"
      >
        <el-form-item prop="username">
          <el-input
            v-model="loginForm.username"
            placeholder="请输入账号"
            prefix-icon="iconfont icon-user"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            v-model="loginForm.password"
            placeholder="请输入密码"
            prefix-icon="iconfont icon-3702mima"
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item prop="showName">
          <el-input
            v-model="loginForm.showName"
            placeholder="请输入昵称"
            prefix-icon="iconfont"
          ></el-input>
        </el-form-item>
        <el-form-item prop="email">
          <el-input
            v-model="loginForm.email"
            placeholder="请输入邮箱"
            prefix-icon="iconfont"
          ></el-input>
        </el-form-item>
        <el-form-item prop="tel">
          <el-input
            v-model="loginForm.tel"
            placeholder="请输入手机号"
            prefix-icon="iconfont"
          ></el-input>
        </el-form-item>

        <el-form-item class="btns">
          <el-button type="primary" @click="register">注册账号</el-button>
          <el-button type="info" @click="closeRegisterForm">关闭</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { registerUser } from "../api/api";

export default {
  name: "Register",
  data() {
    return {
      loginForm: {
        username: "",
        password: "",
        showName: "",
        email: "",
        tel: "",
      },
      loginFormRules: {
        username: [
          {
            required: true,
            message: "请输入登录名称",
            trigger: "blur",
          },
          {
            min: 3,
            max: 10,
            message: "长度在 3 到 10 个字符",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
          {
            min: 6,
            max: 15,
            message: "长度在 6 到 15 个字符",
            trigger: "blur",
          },
        ],
        showName: [
          {
            required: true,
            message: "请输入昵称",
            trigger: "blur",
          },
          {
            min: 5,
            max: 40,
            message: "长度在 5 到 40 个字符",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "请输入邮箱",
            trigger: "blur",
          },
        ],
        tel: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur",
          },
          {
            min: 11,
            max: 11,
            message: "手机号的长度为11",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    closeRegisterForm() {
      this.$emit("registerCancel");
    },
    register() {
      this.$refs.loginFormRef.validate(async (valid) => {
        if (!valid) return;
        registerUser(
          {
            username: this.loginForm.username,
            password: this.loginForm.password,
            showName: this.loginForm.showName,
            email: this.loginForm.email,
            tel: this.loginForm.tel,
          },
          (data) => {
            if (data.code === 200) {
              this.$emit("registerSuccess");
            }
          },
          (errResp) => {
            this.$notify.error({
              title: "错误",
              message: `注册失败,原因是${errResp.data.data}`,
            });
          }
        );
      });
    },
  },
};
</script>

<style scoped>
.login_box {
  width: 450px;
  height: 500px;
  max-width: calc(100% - 20px);
  background-color: #fff;
  border-radius: 3px;
  position: absolute;
  left: 50%;
  top: 400px;
  transform: translate(-50%, -50%);
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  z-index: 1000
}

.login_box .avatar_box {
  height: 130px;
  width: 130px;
  border: 1px solid #eee;
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0 0 10px #ddd;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
}

.login_box .avatar_box img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #eee;
}

.btns {
  display: flex;
  justify-content: flex-end;
}

.login_form {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}
</style>